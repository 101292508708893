/* BUTTON */

.btn {
	display: inline-block;
	min-width: 120px;
	padding: 8px 16px;
	border: none;
	border-radius: 4px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
	outline: none;
	line-height: 16px;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn:not(:disabled) {
    cursor: pointer;
}

/* SUCCESS */

.btn-success {
	color: #fff;
	background-color: #30a626;
}

.btn-success:hover {
	background-color: #58bb2b;
}

.btn-success:active {
	box-shadow: 0 0 0 .2rem rgba(40,167,69,.5);
}

/* WARNING */

.btn-warning {
	color: #fff;
	background-color: #dbb542;
}

.btn-warning:hover {
	background-color: #e4c054;
}

.btn-warning:active {
	box-shadow: 0 0 0 .2rem rgba(255,193,7,.5);
}

/* DANGER */

.btn-danger {
	color: #fff;
	background-color: #dc3545;
}

.btn-danger:hover {
	background-color: #ff6776;
}

.btn-danger:active {
	box-shadow: 0 0 0 .2rem rgba(220,53,69,.5);
}